import Vue from "vue";

/*TITLE*/
document.title = "El Parc de la Sínia | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "El Parc de la Sínia";
Vue.prototype.$subtitle = "Estrena un nuevo concepto de vida urbana, en Martorell";

/*INTRO*/
Vue.prototype.$promoter = "El Parc de la Sínia";
Vue.prototype.$introSubtitle = "Estrena un nuevo concepto de vida urbana, en Martorell";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-opc.-3-ac4-habitat-minnesota--20230127090132.jpg";
Vue.prototype.$image_kitchen = "cocina-park-blanco-roble-organico-krion-snow-white--20230127090155.jpg";
Vue.prototype.$image_bath1 = "banop-combi.-1-sion-white-nature-old-white--20230127090119.jpg";
Vue.prototype.$image_bath2 = "banosec-combi.-1-sion-white-nature-china-blanco--20230127090125.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-opc.-3-ac4-habitat-minnesota--20230127090132.jpg",
  },
  {
    src: "salon-opc.-2-ac4-residence-nevada--20230127090138.jpg",
  },
  {
    src: "salon-opc.-1-ac4-habitat-montana--20230127090145.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-park-blanco-roble-organico-krion-snow-white--20230127090155.jpg",
  },
  {
    src: "cocina-park-blanco-bison-moon-white--20230127090102.jpg",
  },
  {
    src: "cocina-park-blanco-blanco-nubol-moon-white--20230127090109.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-combi.-1-sion-white-nature-old-white--20230127090119.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-combi.-1-sion-white-nature-china-blanco--20230127090125.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/LA_SINIA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/Cstbr6INML4";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carrer%20d'enric%20prat%20de%20la%20riba%2008760%20martorell",
    text: "Carrer d'Enric Prat de la Riba. 08760 Martorell",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20ausiàs%20march%202%2008760%20martorell",
    text: "C/Ausiàs March,2. 08760 Martorell",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-email",
    link: "mailto:info@elparcdelasinia.com",
    text: "info@elparcdelasinia.com",
  },
];
